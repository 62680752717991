import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

const theme = {
  primaryColor: "#663399",
  backgroundColor: "#FFFFFF",
  secondaryBackgroundColor: "#F1F1F1",
  textColor: "#282D70",
  font: "'Open Sans', sans-serif",
};

const LandingPage = () => {
  return (
    <div style={{ backgroundColor: theme.backgroundColor, color: theme.textColor, fontFamily: theme.font, minHeight: "100vh" }}>
      <Container style={{ padding: "2rem" }}>
        {/* Header message */}
        <h1 style={{ color: theme.textColor, textAlign: "center", marginBottom: "2rem" }}>
          Hier vind je alle STARC apps!
          Deze zijn gemaakt door en voor onze collega's!
        </h1>
        
        <Row className="d-flex align-items-stretch">
          {/* Tile 1 */}
          <Col sm={12} md={6} lg={4} className="mb-4">
            <Card className="h-100" style={{ backgroundColor: theme.secondaryBackgroundColor }}>
              <Card.Body className="d-flex flex-column">
                <Card.Title>AI Scraper</Card.Title>
                <Card.Text>
                  AI-Informatieplatform dat inzicht geeft in de Nederlandse bouwsector.
                </Card.Text>
                <div className="mt-auto">
                  <Button
                    as="a"
                    href="https://starcaihub.nl"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ backgroundColor: theme.primaryColor, borderColor: theme.primaryColor }}
                  >
                    Start App
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
          {/* Tile 2 */}
          <Col sm={12} md={6} lg={4} className="mb-4">
            <Card className="h-100" style={{ backgroundColor: theme.secondaryBackgroundColor }}>
              <Card.Body className="d-flex flex-column">
                <Card.Title>Periodiek Systeem</Card.Title>
                <Card.Text>
                  Eén system waar STARC kennis en ervaring opgeslagen is. Een system dat kan adviseren, reageren en voorspellen.
                </Card.Text>
                <div className="mt-auto">
                  <Button
                    as="a"
                    href="https://periodieksysteem.wonderfulmeadow-7b8dbc89.westeurope.azurecontainerapps.io"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ backgroundColor: theme.primaryColor, borderColor: theme.primaryColor }}
                  >
                    Start App
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
          {/* Tile 3 */}
          <Col sm={12} md={6} lg={4} className="mb-4">
            <Card className="h-100" style={{ backgroundColor: theme.secondaryBackgroundColor }}>
              <Card.Body className="d-flex flex-column">
                <Card.Title>PDFs Samenvoegen</Card.Title>
                <Card.Text>
                  Veilig PDF-bestanden samenvoegen!
                </Card.Text>
                <div className="mt-auto">
                  <Button
                    as="a"
                    href="https://starcpdftool-b8atfwbmfddggee4.westeurope-01.azurewebsites.net/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ backgroundColor: theme.primaryColor, borderColor: theme.primaryColor }}
                  >
                    Start App
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

      </Container>
    </div>
  );
};

export default LandingPage;
