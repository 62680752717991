import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Navbar, Container, Nav } from 'react-bootstrap';
import logo from './logo.svg';
import LandingPage from './components/LandingPage';


function App() {
  return (
    <div className="App">
      {/* Top Navbar */}
      <Navbar bg="light" variant="light" expand="lg">
        <Container>
          {/* Logo */}
          <Navbar.Brand href="/">
            <img
              src={logo}
              alt="Logo"
            />
            {' '} {/* Adds a space between logo and text */}
            App Portal
          </Navbar.Brand>
          
          {/* Collapsible Navbar Toggle */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          
          {/* Links */}
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#over">Over</Nav.Link>
              <Nav.Link href="#contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Landing Page */}
      <LandingPage />
    </div>
  );
}

export default App;
